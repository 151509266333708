export const isLegalAgeFromBirthday = (birthday) => {
  const ageDifMs = Date.now() - new Date(birthday).getTime();
  const ageDateFromEpoch = new Date(ageDifMs);
  const limit = new Date('1988-01-01'); // epoch + 18 years
  return ageDateFromEpoch.getTime() - limit.getTime() > 0;
};

export const validateTimeInterval = (startTime, endTime) => {
  const startingTime = Date.parse(startTime) || 1;
  const endingTime = Date.parse(endTime) || 999999999999999;
  return Date.now() >= startingTime && Date.now() < endingTime;
};

export const isDateInTheFuture = (date) => {
  const ageDifMs = Date.now() - new Date(date).getTime();
  return ageDifMs > 0;
};
