import { useCart, useProduct } from '@gemini-vsf/composables';
import { ref } from '@nuxtjs/composition-api';
import { useUiNotification } from '~/composables';
import { useI18n } from '~/helpers/hooks/usei18n';
import useAtelier from '../../composables/useAtelier/useAtelier';

export default () => {
  const { cart, bulkRemoveItems, loading, error: cartError } = useCart();
  const { search: productSearch, products: productSearchResult } = useProduct();
  const { atelierStock, getStock } = useAtelier();
  const { send: sendNotification, notifications } = useUiNotification();
  const trans = useI18n();

  const productsNames = ref([]);
  const success = ref(false);
  let retry = 0;

  const removeUnavailableItems = async () => {
    if (!cart.value && retry < 10) {
      retry += 1;
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      setTimeout(() => removeUnavailableItems(), 2000);
    } else if (cart.value) {
      success.value = false;
      const productsIdForStock = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const cartItem of cart.value.items) {
        const sizeLabel = cartItem?.configurable_options?.find((e) => e.option_label === 'size' || e.option_label === 'taglia').value_label;
        if (cartItem?.product?.atelier_id) {
          productsIdForStock[cartItem.product.atelier_id] = {
            cartItem,
            sizeLabel,
          };
        } else {
          const baseSearchQuery = {
            filter: {
              uid: cartItem.product.uid.split('::')[1],
            },
          };
          // eslint-disable-next-line no-await-in-loop
          await productSearch({
            queryType: 'DETAIL',
            customQuery: {
              productDetail: 'atelierId',
            },
            ...baseSearchQuery,
          });
          productsIdForStock[productSearchResult.value?.items[0]?.atelier_id] = {
            cartItem,
            sizeLabel,
          };
        }
      }
      if (Object.keys(productsIdForStock).length > 0) {
        try {
          const toRemove = [];
          await Promise.all(
            Object.keys(productsIdForStock).map(async (atelierId) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              await getStock(atelierId, productsIdForStock[atelierId]?.sizeLabel);
              if (atelierStock.value - (productsIdForStock[atelierId]?.cartItem?.quantity || 1) < 0) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                toRemove.push(productsIdForStock[atelierId].cartItem);
                productsNames.value.push(productsIdForStock[atelierId].cartItem.product.name);
              }
            })
          ).catch((e) => {
            console.error('Error - could not fetch stock', e);
          });
          if (toRemove.length > 0) {
            await bulkRemoveItems({
              products: toRemove,
            });
            if (!cartError.value.bulkRemoveItems) {
              success.value = true;
              sendNotification({
                id: Symbol('Atelier OOS'),
                message: `${trans.t("We're sorry, but the following items are no longer available and were removed from your cart:")}
              ${productsNames.value.join('; ')}`,
                type: 'danger',
                icon: 'error',
                persist: false,
                title: 'Error',
              });
            }
          }
        } catch (e) {
          console.error('OOS removal error:', e);
        }
      }
    } else {
      console.debug('Unable to load cart.');
    }
  };

  return {
    removeUnavailableItems,
    productsNames,
    success,
  };
};
