/* eslint-disable import/no-extraneous-dependencies */
import { Route } from 'vue-router';

export const fixUrlForCurrentLocale = (path: string, route: Route): string => {
  const getCurrentLocale = () => {
    let tempCurrentLocale = '';
    const currentRoute = route?.fullPath ? route?.fullPath.split('/') : null;
    if (currentRoute && Array.isArray(currentRoute) && currentRoute.length > 0) {
      tempCurrentLocale = currentRoute?.[1];
      if (tempCurrentLocale?.includes('?')) {
        const currentLocaleWithoutParameters = tempCurrentLocale.split('?');
        if (currentLocaleWithoutParameters && Array.isArray(currentLocaleWithoutParameters) && currentLocaleWithoutParameters.length > 0) {
          tempCurrentLocale = currentLocaleWithoutParameters?.[0];
        }
      }
    }
    return tempCurrentLocale;
  };

  const fixRouteForLocale = (routeToFix: string): string => {
    let newPath = routeToFix;
    if (routeToFix?.includes("'")) {
      newPath = routeToFix.replaceAll("'", '');
    }
    if (newPath?.[0] !== '/') {
      newPath = `/${newPath}`;
    }
    const routeArray = newPath.split('/');
    const currentLocale = getCurrentLocale();
    if (routeArray.length > 1) {
      const alreadyHasLocale = routeArray?.includes('it') || routeArray?.includes('en') || routeArray?.includes(currentLocale);
      if (alreadyHasLocale) {
        routeArray[1] = currentLocale;
      } else {
        routeArray[0] = currentLocale;
        routeArray.unshift('');
      }
      return routeArray.join('/');
    }
    return newPath === '/' ? `${newPath}${currentLocale}` : `${currentLocale}${newPath}`;
  };

  return fixRouteForLocale(path);
};
