export const jsHelpers = () => {
  const arrayIsValidAndHasItems = (array: []): boolean => {
    return Array.isArray(array) && array.length > 0;
  };

  const objectIsNotEmpty = (object: Record<string, unknown>): boolean => {
    return object && Object.keys(object).length === 0 && Object.getPrototypeOf(object) === Object.prototype;
  };

  const vueObjectToNormalObject = (object: Record<string, unknown>): Record<string, unknown> => ({ ...object });
  const vueArrayToNormalArray = (array: []): [] => [...array];

  return {
    arrayIsValidAndHasItems,
    objectIsNotEmpty,
    vueObjectToNormalObject,
    vueArrayToNormalArray,
  };
};

export default jsHelpers;
